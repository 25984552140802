<template>
  <page-wrapper
    :isLoading="isLoading"
    :hasFilters="true"
    @submitFilters="getLinksList"
  >
    <template #title> Referral Links </template>
    <template #titleBtn>
      <b-button
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        class=""
        variant="success"
        @click="downloadFile"
      >
        Download Excel File
      </b-button>
    </template>
    <template #filters>
      <custom-date-picker
        @getSelectedDate="setCreateDateFilter"
        inputLabel="Create Date Of Link"
        inputPlaceholder="2023-12-06"
        inputId="createDateOfLink"
      ></custom-date-picker>
      <search-input
        @enter="getLinksList"
        v-model="linksListFilters.code"
        inputId="codeFilter"
        inputLabel="Search By Code"
        inputPlaceholder="h5B7SNKVC"
      ></search-input>
      <search-input
        @enter="getLinksList"
        v-model="linksListFilters.fullName"
        inputId="fullNameFilter"
        inputLabel="Search By User Name"
        inputPlaceholder="s y"
      ></search-input>
      <search-input
        @enter="getLinksList"
        v-model="linksListFilters.registerUserCount"
        inputId="registerUserCountFilter"
        inputLabel="Search By Registered Users Count"
        inputPlaceholder="5"
      ></search-input>
    </template>
    <custom-table
      :pageNumber="linksListFilters.pageNumber"
      :count="linksListFilters.count"
      :totalCount="totalCount"
      :tableItems="linksList"
      :tableColumns="myTableColumns"
      @changePage="changePage"
    >
    </custom-table>
  </page-wrapper>
</template>
<script>
export default {
  title: "Referral Links",
  mounted() {
    this.getLinksList();
  },
  data() {
    return {
      isLoading: false,
      linksList: null,
      totalCount: null,
      linksListFilters: {
        pageNumber: 1,
        count: 10,
        id: null,
        createDate: null,
        code: null,
        fullName: null,
        registerUserCount: null,
      },
      myTableColumns: [
        {
          key: "code",
          label: "Code",
        },
        {
          key: "name",
          label: "Campaign Name",
          formatter: (value) => (value ? value : "-"),
        },
        {
          key: "fullName",
          label: "Full Name",
        },
        {
          key: "createDate",
          label: "Create Date",
          formatter: (value) => {
            return `${new Date(value).toLocaleDateString("en-US")} - ${new Date(
              value
            ).toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            })}`;
          },
        },
        {
          key: "registerUserCount",
          label: "Number Of Affiliates",
        },
        {
          key: "invidePrice",
          label: "Gift Affiliate Rewards",
          formatter: (value) => `${parseFloat(value)} $`,
        },
        {
          key: "releaseInvidePriceProgressing",
          label: "Rewards in release process",
          formatter: (value) => `${parseFloat(value)} $`,
        },
        {
          key: "releaseInvidePrice",
          label: "Rewards Withdrawn",
          formatter: (value) => `${parseFloat(value)} $`,
        },
        {
          key: "commission",
          label: "Comission",
          formatter: (value) => `${parseFloat(value)} $`,
        },
        {
          key: "avaliableForReleaseCommission",
          label: "Comission Available To Cashout",
          formatter: (value) => `${parseFloat(value)} $`,
        },
        // {
        //   key: "releaseCommissionProgressing",
        //   label: "Comission From Invited Users In Cashout Process",
        //   formatter: (value) => `${parseFloat(value)} $`,
        // },
        {
          key: "releaseCommission",
          label: "Comissions collected",
          formatter: (value) => `${parseFloat(value)} $`,
        },
      ],
    };
  },
  methods: {
    setCreateDateFilter(date) {
      this.linksListFilters.createDate = date;
    },
    changePage(pageNumber) {
      this.linksListFilters.pageNumber = pageNumber;
      this.getLinksList();
    },
    async downloadFile() {
      let _this = this;
      _this.overlay = true;
      let userToken = getUserData().data.token;

      await axiosIns
        .post(`UserInviteLink/GetListForExportExel`, null, {
          responseType: "blob",
          Authorization: userToken,
        })
        .then((resp) => {
          _this.overlay = false;
          saveAs(resp.data, "ReferralLinksList.xlsx");
        })
        .catch((error) => {
          _this.overlay = false;
          console.log(error);
        });
    },
    async getLinksList() {
      try {
        this.isLoading = true;
        let _this = this;
        let getLinksListForAdmin = new GetLinksListForAdmin(_this);
        getLinksListForAdmin.setRequestParamDataObj(_this.linksListFilters);
        await getLinksListForAdmin.fetch((res) => {
          _this.totalCount = res.total;
          _this.linksList = res.data;
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    PageWrapper,
    CustomDatePicker,
    SearchInput,
    CustomTable,
    BButton,
  },
};
import PageWrapper from "@/views/components/Utilities/PageWrapper.vue";
import CustomDatePicker from "@/views/components/Utilities/CustomDatePicker.vue";
import SearchInput from "@/views/components/Utilities/SearchInput.vue";
import CustomTable from "@/views/components/Utilities/CustomTable.vue";
import axiosIns from "@/libs/axios";
import { saveAs } from "file-saver";
import { getUserData } from "@/auth/utils";
import { BButton } from "bootstrap-vue";
import { GetLinksListForAdmin } from "@/libs/Api/UserInviteLink";
</script>
